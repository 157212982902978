import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import { useStoreState, useStoreActions } from 'easy-peasy';


const InvestmentTypeSelect = ({ label, name, onChange, showAll=true,showSubsOptions=false, value, icon, disabled,}) => {
  const { t } = useTranslation();
  const { enums, getSecurityType } = useAppContext()

  const myAccount = useStoreState((state) => state.account?.account);

  
  const getInputOptions = useCallback(() => {
    let investmentTypes = enums?.transactions?.transaction?.related_model_type
    const subTypes = enums?.private_investment_funds?.private_investment_fund?.type
    const always_supported_keys = [10, 40, 30, 'private_stock',"all", 'private_investment_fund','private_investment_fund_unlisted', 'derivative', 'loans_listed', 'loans', 'other','index','listed_stock']

    if (showSubsOptions && investmentTypes) {
      investmentTypes = investmentTypes?.filter(obj =>  obj?.key !== 20)
      investmentTypes = [...investmentTypes, ...subTypes]
    } else {
      investmentTypes = investmentTypes?.filter(obj => obj?.key !== 20)
    }

    // Remove elements that don't meet the condition
    if (investmentTypes && myAccount?.license?.features) {
      investmentTypes = investmentTypes.filter(obj => {
        return always_supported_keys.includes(obj.key) || myAccount?.license?.features.some(feature => feature.name === obj?.key);
      });
    }

    return investmentTypes && investmentTypes.map(obj => {

      if (obj.key !== 10 && obj.key !== 40 && obj.key !== 30){
        return (
          <option style={value ==obj.key? { paddingLeft:'20px' }: {paddingLeft:"0px"}} className="" value={obj.key}>&nbsp;&nbsp;   {t(obj.key, obj.value)} </option>
        )
      }
      return (
        <option  value={obj.key}> {t(obj.key,obj.value)}  </option>
      )
    })
  }, [enums])


  // (obj.key !== 10 && obj.key !== 40 && obj.key !== 30) && "pl-5"
  
  return (
    <Input  
      label={label || label === "" ? label : t("investment_type_select_label", "Rahoitusinstrumentti")} 
      type="select" 
      name={name} 
      onChange={onChange} 
      value={value} 
      disabled={disabled}
      icon={icon}

       

    >
      {/*<option value=""></option>*/}
      {showAll &&
        <option value="" label={t("Kaikki")}></option>
      }
    
      {getInputOptions()}
    </Input>
  )
  
  
}

export default InvestmentTypeSelect;