import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Popover } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Checkbox, Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { useAppContext } from "contexts/AppContext"
import { round,  roundScaleDecimals, thousandFormat } from "services/helpers"
import { TableAuto } from "stories/tables"
import SplitCheckbox from "components/Forms/SplitCheckbox/SplitCheckbox"


import { 
  httpGetTransaction, 
  httpCreateTransaction, 
  httpUpdateTransaction, 
  httpDeleteTransaction, 
  httpGetStockDateBalance, 
  httpCreatePrivateStockTransaction, 
  httpUpdatePrivateStockTransaction,
  httpDeletePrivateStockTransaction,
  httpGetPrivateStockDateBalance,
  httpGetPrivateStockTransaction,
  httpGetPrivateStockTransactions,
  httpArrayCreateSplitTransaction
} from "services/transactions"




const defaultValues = {
//   id: null,
//   name: "",
}

const SplitTransactionEdit = (props) => {
  const { getPortfolioName, getUserTypeName, getTransactionType } = useAppContext()

  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      object,
      dataObject,
      dataObjectArray,
      selectedPortfolios,
      setSelectedPortfolios,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    //convert data to list




    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        //setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }

  const [data, setData] = useState()

  useEffect(() => { 
    if (object) {
      const dataObject = object.reduce((acc, curr, index) => {
        const id = curr?.batch?.id;
        const key = "rowId_" + id ;
        const value = {
          id: id,
          new_quantity: roundScaleDecimals((curr?.batch?.batch_balance* curr?.splits?.dividend),6),
          portfolio: curr?.batch?.portfolio,
          original_batch_balance: curr?.batch?.original_batch_balance,
          price: curr?.batch?.price,
          value_total: curr?.batch?.value_total,
          batch_balance: curr?.batch?.batch_balance,
          
          // portfolio: curr?.batch?.portfolio,
          // id: id,
          // new_quantity: Number(curr?.batch?.batch_balance)* Number(curr?.splits?.dividend),
        };
        return { ...acc, [key]: value };
      }, {});
      setData(dataObject);
      if (data) {
        const data1 = Object.values(dataObject).map(item => {
          return {
            ...item,
            id: item.id,
            new_quantity: item.new_quantity,

          }
        })
        setFieldValue("data", data1)
      }
      // setFieldValue("data", dataObject)
      
    }
  }, [object]);

  useEffect(() => {
    if (data) {
      const data1 = Object.values(data).map(item => {
        return {
          ...item,
          id: item.id,
          new_quantity: item.new_quantity,
        }
      })
      setFieldValue("data", data1)
    }
    // setFieldValue("data", data)

  }, [data])
  




  const headers = [
    { label: t("split_transactions_group_create_table_portfolio", "Salkku"), key: "portfolio"},
    { label: t("split_transactions_group_create_table_batch", "Erä id"), key: "batch"},
    { label: t("split_transactions_group_create_table_instrument_name", "Instrumentti"), key: "instrument_name"},
    { label: t("split_transactions_group_create_table_type", "Tyyppi"), key: "transaction_type"},
    { label: t("split_transactions_group_create_table_batch_purchase_date", "Hankintapäivä"), key: "batch_purchase_date"},
    { label: t("split_transactions_group_create_table_batch_balance", "Salkussa kpl"), key: "batch_balance"},

    { label: t("split_transactions_group_create_table_batch_price", "hinta kpl"), key: "price"},
    { label: t("split_transactions_group_create_table_split_id", "Split id"), key: "split_id"},
    { label: t("split_transactions_group_create_table_split_dividend", "Kerroin"), key: "dividend"},
    { label: t("split_transactions_group_create_table_split_date_effective", "SPLIT Päivä"), key: "date_effective"},
    { label: t("split_transactions_group_create_table_new_quantity", "Uusi määrä"), key: "new_quantity"},

  { label: t("portfolios_list_actions", " "), key: "actions"},
];
  
  // const handleDelete = () => {
  //   httpDeletePortfolio(object.id).then(() => {
  //     props.handleResolve()
  //   })
  // }

  const calculateNewValues = () => {

    let newBalance = object?.batch?.batch_balance * values?.split_dividend
    let newPrice = object?.batch?.price / values?.split_dividend
    setFieldValue("new_batch_balance", newBalance)
    setFieldValue("new_price", roundScaleDecimals(newPrice, 6))
  }

  const returnOrignalValue = (row) => {

    const id =  row?.batch?.id;

    setData(s => ({
        ...s,
        [`rowId_${id}`]: {
            ...s[`rowId_${id}`],
      
            "new_quantity": roundScaleDecimals(Number(row?.batch?.batch_balance)* Number(row?.splits?.dividend), 6),
        }
    }))
}

  const handleInputChange = ({target}, rowId) => {
      setData(s => ({
          ...s,
          [`rowId_${rowId}`]: {
              ...s[`rowId_${rowId}`],
              [target.name]: target.value
          }
      }))
  }

  const getValue = (rowId, fieldName, index) => {

      if (data === undefined) return ""
      return data[`rowId_${rowId}`]?.[fieldName]
  }

  const getTableRows = useCallback((rows) => {
    let quantity_summary = 0
    let new_quantity_summary = 0

    for (let i = 0; i < rows.length; i++) {
      quantity_summary += rows[i]?.batch?.batch_balance
      const value = getValue( rows[i]?.batch?.id, "new_quantity")
      new_quantity_summary += Number(value)
    }
    
    const summary_name = t("split_transactions_group_create_summary", "Yhteensä")

    rows.push({
      portfolio: summary_name,
      batch_balance: roundScaleDecimals(quantity_summary,6),
      new_quantity: roundScaleDecimals(new_quantity_summary, 6),
      splits: {dividend: rows?.[0].splits?.dividend},
      batch:{related_model:{name: rows?.[0].batch?.related_model?.name}}
    })

    return rows && rows.map((row, index) => {
      let batch_balance = Number(row?.batch?.batch_balance)
      return {
        ...row,

        batch: row?.batch?.id,


        portfolio: row.portfolio==summary_name?<><Typography variant="h5" className="text-dark">{row.portfolio}</Typography></>: getPortfolioName(row?.batch?.portfolio),
        instrument_name:row?.batch?.related_model?.name,
        batch_balance: row.portfolio==summary_name?thousandFormat(roundScaleDecimals(quantity_summary,6)): thousandFormat(roundScaleDecimals(batch_balance,6)),
        price: row.portfolio==summary_name?null: thousandFormat(roundScaleDecimals(Math.abs(row?.batch?.price), 6)),
        dividend: row?.splits?.dividend,
        split_id: row?.splits?.id,
        transaction_type: t("transactionTypeValue_2", getTransactionType(2).value), 

        date_effective: row.portfolio==summary_name?null: moment(row?.splits?.date_effective).format('DD.MM.YYYY'),
        batch_purchase_date:row.portfolio==summary_name?null: moment(row?.batch?.batch_purchase_date).format('DD.MM.YYYY'),
        new_quantity: (
          <>
          <Input 
            name="new_quantity" 
            onChange={(e) => handleInputChange(e,row?.batch?.id,)} 
            value={row.portfolio==summary_name?roundScaleDecimals(new_quantity_summary,6): getValue( row?.batch?.id, "new_quantity")}
            type="number"
            onWheel={(e) => e.target.blur()}
            disabled={row.portfolio==summary_name?true:false}
            placeholder={t("split_transactions_group_create_summary_new_quantity", t("Uusi määrä"))}
          />
       

          </>
        ),
        actions: row.portfolio!==summary_name && (
          <div className="d-flex justify-content-end">

            <Button className="btn-sm " onClick={() => returnOrignalValue(row)} color="secondary" >{t("Palauta")}</Button>
          </div>
        ),
      }
    })
  }, [object, data])


  const handleCheckboxChange = (portfolioId) => {
    console.log("portfolioId", portfolioId)
    // if (selectedPortfolios.includes(portfolioId)) {
    //   setSelectedPortfolios(selectedPortfolios.filter((id) => id !== portfolioId))
    // } else {
    //   setSelectedPortfolios([...selectedPortfolios, portfolioId])
    // }
  };


  const getPortfolioTables = useCallback(() => {
    const data1 = object;
    const organizedData = {};
    if (data1 === undefined) return null;


    // Iterate over the original data crouping by the split id
    for (const item of data1) {
      const portfolioId = item.batch.portfolio;
      
      if (organizedData[portfolioId]) {
        organizedData[portfolioId].push(item);
      } else {
        organizedData[portfolioId] = [item];
      }
    }

    const organizedDataArray = Object.values(organizedData);
    
    return organizedDataArray.map((portfolioData, index) => {
      return( 
        <><TableAuto
        showRowNumber={false}
        showId={false}
        checkboxes={false}
        headers={headers}
        headerColor={"light"}
        rows={getTableRows(portfolioData)}
        // loading={loading}
        pagination={false}
        // paginationPrevious={paginationPrevious}
        // paginationNext={paginationNext}
        // paginationOffset={filters?.offset}
        // paginationLimit={filters?.limit}
        // paginationCount={count}
        // setFilters={setFilters}
        className="mb-2"
      />
      <div  className="mb-5">
        
        <SplitCheckbox portfolio={portfolioData?.[0]?.batch.portfolio} label={t("split_transactions_group_create_pass_split", "Ohita splitin käsittely tämän salkun osalta. Luo tarvittaessa vaihto")} selectedPortfolios={selectedPortfolios} setSelectedPortfolios={setSelectedPortfolios} />
      </div>
      </>
      )
   
    })
  }, [object, data])

  const getErrorMessages = (errorsData) => {
    return errorsData && errorsData.map((error, index) => {
      return (
        <><Typography key={index} className="text-danger">{error?.new_quantity}</Typography></>
      )
    })
  }


  
  return (
    <>
      
        <Row className="my-4 mb-3">
        <Col xs={12} md={6} className="ml-auto pb-4">
        
        <div className="d-flex justify-content-start mb-2">
        
        <i className="fas fa-exclamation-triangle text-danger fa-2x mr-3" />
        <Typography variant="h2">{t("split_transactions_group_create_handle_split", "Käsittele split kirjaukset")}</Typography>
        </div>
        </Col>
        <Col xs={12} md={6} className="ml-auto">
        
        </Col>
        </Row>

        {object && getPortfolioTables()}

        <Button color="warning" id="info" type="button" size="sm">
              <i className="fas fa-info-circle fa-lg"/>
            </Button>
            <Popover
              placement="top"
              target="info"
            >       
              <Typography className="text-warning mb-1" variant="medium">
              {t("split_transactions_group_create_info_text", "HUOM! Tee tarvittavat kirjaukset, jotka ajoittuvat aikaan ennnen splitin voimaantuloa, ennenkuin hyväksyt muutokset.")}
              </Typography>  
            </Popover>

      <Row className="mt-4">
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="primary">{t("Close","Sulje")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
         {/*  <Button onClick={handleDelete} disabled={true} variant="danger">{t("Delete","Poista")}</Button>*/}
        </Col>
        ) : null }
        <Col className="mb-3 text-right">


            <Button name="split_submit" variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("split_transactions_group_create_submit","Hyväksy muutos ehdotukset")}
            </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          { Boolean(errors["data"])  && errors['general'] && <>{getErrorMessages(errors['data'])}</> }
        </Col>
      </Row>


    </>
  );
}

const SplitTransactionEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t, dataObjectArray} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        data: Yup.array().of(
          Yup.object().shape({
            id: Yup.number().required(`${t("The field")} ${t("id")} ${t("is required")}`),
            new_quantity: Yup.number().min(0.00000000000001,(value) => {
              const data = dataObjectArray
              const [, index] = value.path.match(/data\[(\d+)\]/);
              const item = data[index];
              return `${t(`Erän`)} ${item.id} ${t("uusi määrä on oltava enemmän kuin 0")}`;
            }).required((value) => {
              const data = dataObjectArray
              const [, index] = value.path.match(/data\[(\d+)\]/);
              const item = data[index];
              return `${t(`Erän`)} ${item.id} ${t("uusi määrä on pakollinen")}`;
            }),
          })
        )
      });
    },

    // validationSchema: props => {
    //   const { t } = props;
    //   const required = t('The field is required');
      
    //   // Define the Yup schema for each object in the dictionary
    //   const objectSchema = Yup.object().shape({
    //     id: Yup.number().required(),
    //     new_quantity: Yup.number().min(1).required(),
    //   });
      
    //   // Define the Yup schema for the entire dictionary
    //   const dictionarySchema = Yup.object().shape({
    //     data: Yup.object().shape({
    //       [Yup.string()]: objectSchema,
    //     }),
    //   });
      
    //   return dictionarySchema;
    // },
    

    // validationSchema: props => {
    //   const {t} = props;
    //   const required = t("The field is required");
    //   return Yup.object().shape({
    //     data: Yup.object().shape({
    //   });
    // },

    // validationSchema: props => {
    //   const { t } = props;
    //   const required = t("The field is required");
    
    //   return Yup.object().shape({
    //     data: Yup.object().shape({
    //       "rowId_*": Yup.object().shape({
    //         id: Yup.number().required(required),
    //         new_quantity: Yup.number().min(1, "New quantity must be greater than 0").required(required),
    //       }),
    //     }),
    //   });
    // },

    // validationSchema = Yup.object().shape({
    //   items: Yup.array().of(
    //     Yup.object().shape({
    //       id: Yup.number().required(),
    //       name: Yup.string().required(),
    //       quantity: Yup.number().min(1).required(),
    //     })
    //   )
    // });

    // validationSchema: (props) => {
    //   const { t } = props;
    //   const required = t('The field is required');
  
    //   // Define the Yup schema for the data dictionary
    //   const dataSchema = Yup.object().shape({
    //     [Yup.string()]: Yup.object().shape({
    //       id: Yup.number().required(),
    //       new_quantity: Yup.number().required(),
    //     }),
    //   });
    
    //   // Define the custom validation function for the data dictionary
    //   const validateData = (data) => {
    //     console.log(data)
      
    //     let errors = {};
    //     Object.entries(data).forEach(([key, value]) => {
    //       console.log("value", value)
    //       //check if key is not include "row" string
    //       if (!key.includes('row')) {
    //         return;
    //       }




    //       if (value.new_quantity === 0) {
    //         errors = { ...errors, [key]: 'New quantity cannot be zero' };
    //       }
    //     });
    //     return errors;
    //   };
  
    //   // Return the final schema with the custom validation function
    //   return Yup.object().shape({
    //     data: dataSchema.test('validate-data', required, validateData),
    //   });
    // },




    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account, object, selectedPortfolios} = props;

      
      let data = {
        data: values?.data?.map((item) => {
          const original_batch_balance = roundScaleDecimals(item?.new_quantity/ item?.batch_balance * item?.original_batch_balance, 6);
          const price = roundScaleDecimals(item?.price / ( item?.new_quantity/ item?.batch_balance  ),6);
          return {
            ...item,
            id: item?.id,
            batch_balance: item?.new_quantity,
            date_transaction: object[0].splits?.date_effective,
            stock_split: object[0]?.splits?.id,
            original_batch_balance: original_batch_balance,
            price: price,
            value_total: item?.value_total,
            related_model_type: object[0]?.batch?.related_model_type,  
            related_model_id: object[0]?.batch?.related_model_id,
            type: 20,
            batch_id: item.id,
          }
        }),

        
          
        // commission_total: object?.batch?.commission_total,
        // currency_exchange_rate: object?.batch?.currency_exchange_rate,
        // currency_label: object?.batch?.currency_label,
        // price: values?.price,
        // price_total: values?.price_total,
        // stock: values?.stock_private_obj?.id,
        // stock: values?.stock?.id,
        // stock_market: stockMarket,
        // fifo_used: values?.fifo_used? values?.fifo_used : false,
        // state: values?.state?.key,
        // date_settlement: values?.date_settlement? moment(values?.date_settlement).format() : undefined,
        // quantity: values?.new_batch_balance,
        // portfolio: object?.batch?.portfolio,
        // type: 20, // Split
        // date_transaction: object?.splits?.date_effective,
        // related_model_id: object?.batch?.related_model_id,
        // related_model_type: object?.batch?.related_model_type,
        // value_total: object?.batch?.value_total,
        // batch_id: object?.batch?.batch_id?object?.batch?.batch_id:object?.batch?.id,
        // batch_balance: values?.new_batch_balance,
        // stock_split: object?.splits?.id,
        // original_batch_balance: original_batch_balance,
        // price: values?.new_price,

      };;
      data.selectedPortfolios= selectedPortfolios
      
      // if (values?.id) {
      //   // httpUpdatePortfolio(values.id, data).then(res => {
      //   //   setSubmitting(false);
      //   //   props.notify({ title:t("Portfolio"), message:t("Updated")})
      //   //   props.handleResolve()
          
      //   // }, error => {
      //   //   setSubmitting(false);
      //   //   if (error?.data?.detail) {
      //   //     setErrors({
      //   //       general: error?.data?.detail,
      //   //     })
      //   //   }
      //   //   else {
      //   //     setErrors({
      //   //       general: t("Unknown error. Please try again later or contact support.")
      //   //     })
      //   //   }
      //   // })
      // }
      
      httpArrayCreateSplitTransaction(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("split_transactions_group_create_added","Split kirjaus"), message:t("lisätty onnistuneesti")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail || error?.data?.general) {
            setErrors({
              general: error?.data?.detail || error?.data?.general,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      
      
    },
    displayName: "BasicForm"
  
})(SplitTransactionEdit)
    
    
const SplitTransactionEditView = ({history, object, modalOpen, ...rest}) => {
  // API requests here

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
const { getPortfolioName, getUserTypeName } = useAppContext()

  
  // const getObject = (id) => {
    
  //   setLoading(true)
    
  //   httpGetPortfolio(id).then(res => {
      
  //     setPreSetValues({
  //       ...defaultValues,
  //       ...res?.data,
  //     });
    
  //   }).finally(response => {
  //     setLoading(false);
  //   })
    
  // } 
  let dataArray = []
  let dataObject = {}
  if (object) {
    const dataObject = object.reduce((acc, curr, index) => {
    const id =  curr?.batch?.id;
    const key = "rowId_" + id ;
    const value = {
      id: id,
      new_quantity: roundScaleDecimals((curr?.batch?.batch_balance* curr?.splits?.dividend), 6),
      portfolio: curr?.batch?.portfolio,
      original_batch_balance: curr?.batch?.original_batch_balance,
      price: curr?.batch?.price,
      value_total: curr?.batch?.value_total,
      batch_balance: curr?.batch?.batch_balance,
      price_total: curr?.batch?.price_total,

    };
    return { ...acc, [key]: value };
  }, {});


  //convert dic to array
  dataArray = Object.values(dataObject).map(item => {
    return {
      id: item.id,
      new_quantity: item.new_quantity,
      portfolio: item.portfolio,
      original_batch_balance: item?.original_batch_balance,
      price: item?.price,
      value_total: item?.value_total,
      batch_balance: item?.batch_balance,
      price_total: item?.price_total,

    }
   })
  } 


  useEffect (() => {
    if (object) {
      setPreSetValues({
        ...defaultValues,
        // ...dataObject,
        data: dataArray,
        // data: dataObject,
      });
    }
  }, [object])

  
  
  // useEffect(() => {
  //   if (objectId && parseInt(objectId) > -1) {
  //     getObject(objectId)
  //   }
  // }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (

    <SplitTransactionEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      object={object}
      account={account}
      dataObject={dataObject}
      dataObjectArray={dataArray}
      selectedPortfolios={selectedPortfolios}
      setSelectedPortfolios={setSelectedPortfolios}
      {...rest} 
    />
  )
  
}

export default SplitTransactionEditView;
