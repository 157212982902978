import http from './api'

export const httpGetPrivateInvestmentFunds = (params) => {
  console.log("httpGetPrivateInvestmentFunds");
  return http({
    url: `/private_investment_funds/private_investment_funds/` ,
    method: 'GET',
    params,
  })
}

export const httpGetAllSecurities = (params) => {
  console.log("httpGetAllSecurities");
  return http({
    url: `/private_investment_funds/private_investment_funds/all_securities/` ,
    method: 'GET',
    params,
  })
}

export const httpGetPrivateInvestmentFund = (id) => {
  console.log("httpGetPrivateInvestmentFund");
  return http({
    url: `/private_investment_funds/private_investment_funds/${id}/` ,
    method: 'GET',
  })
}

export const httpCreatePrivateInvestmentFund = (data) => {
  console.log("httpCreatePrivateInvestmentFund");
  return http({
    url: `/private_investment_funds/private_investment_funds/` ,
    method: 'POST',
    data
  })
}

export const httpUpdatePrivateInvestmentFund = (id, data) => {
  console.log("httpUpdatePrivateInvestmentFund");
  return http({
    url: `/private_investment_funds/private_investment_funds/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeletePrivateInvestmentFund = (id) => {
  console.log("httpDeletePrivateInvestmentFund");
  return http({
    url: `/private_investment_funds/private_investment_funds/${id}/` ,
    method: 'DELETE',
  })
}

// Rates

export const httpGetPrivateInvestimentFundDatas = (params) => {
  console.log("httpGetPrivateInvestimentFundDatas");
  return http({
    url: `/private_investment_funds/private_investment_fund_datas/` ,
    method: 'GET',
    params,
  })
}

export const httpGetPrivateInvestimentFundData = (id) => {
  console.log("httpGetPrivateInvestimentFundData");
  return http({
    url: `/private_investment_funds/private_investment_fund_datas/${id}/` ,
    method: 'GET',
  })
}

export const httpCreatePrivateInvestimentFundData = (data) => {
  console.log("httpCreatePrivateInvestimentFundData");
  return http({
    url: `/private_investment_funds/private_investment_fund_datas/` ,
    method: 'POST',
    data,
  })
}

export const httpUpdatePrivateInvestimentFundData = (id, data) => {
  console.log("httpUpdatePrivateInvestimentFundData");
  return http({
    url: `/private_investment_funds/private_investment_fund_datas/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeletePrivateInvestimentFundData = (id) => {
  console.log("httpDeletePrivateInvestimentFundData");
  return http({
    url: `/private_investment_funds/private_investment_fund_datas/${id}/` ,
    method: 'DELETE',
  })
}

export const httpGetAllSectors = (params) => {
  console.log("httpGetAllSectors");
  return http({
    url: `/private_investment_funds/get_all_sectors/` ,
    method: 'GET',
    params,
  })
}

export const httpUpdateAccountingPrice = (data,params) => {
  console.log("httpCreatePrivateInvestimentFundData");
  return http({
    url: `/private_investment_funds/private_investment_funds/update_accounting_price/` ,
    method: 'POST',
    data,
    params
  })
}