import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner, Modal,Link } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";

import ChangePassword from "views/dashboard/ChangePassword/ChangePassword"
import ChangeUsername from "views/dashboard/ChangeUsername/ChangeUsername"


import { Input, ImageInput ,SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { PageWrapper, PageHeader } from "components/Page"


import { httpGetPortfolio, httpCreatePortfolio, httpUpdatePortfolio, httpDeletePortfolio } from "services/portfolios"
import { httpGetAccountUser, httpUpdateAccountUser, httpDestroyAccountUser } from "services/account_users"
import { httpGetMyUser, httpUpdateUser } from "services/users"

import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import LanguageSelect from "components/Forms/LanguageSelect/LanguageSelect"


import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"

import defaultAvatar from "assets/images/boss-1.png"


import UserTypeSelect from "components/Forms/UserTypeSelect/UserTypeSelect"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"

const defaultValues = {
  id: null,
  username: "",
  first_name: "",
  last_name: "",
  phone: "",
}

const UserEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues,
     invitated_again,
     openModalEdit
   } = props;
   
   const {
      t,
      history,
      objectId,
      account
   } = props;



   console.log(values)
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );

  const [previewImage, setPreviewImage] = useState();

  const handleImageChange = (file) => {
    setFieldValue("new_profile_picture", file)
    setPreviewImage(URL.createObjectURL(file))
  }
  
  const handleResetImage = () => {
    setFieldValue("new_profile_picture", undefined)
    setPreviewImage(null)
    //reload window
    // window.location.reload()
  }
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        console.log(res)

        //if res.new_profile_picture have errors then setErrors to profile_picture
        if (res.new_profile_picture) {
          setErrors({
            ...res,
            profile_picture: res.new_profile_picture
          })
        }
        else {
          setErrors(res)
        }



        setTouched(res);
        // setErrors({
        //   ...res,
        //   general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        // })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDestroyAccountUser(objectId).then(() => {
    props.handleResolve()
    })
  }
  
  return (
    <>


    <div className="mt-3 mb-3 myuser">
      <div className="row">
        <div className="col">
        <div className="col"> <PageHeader title={t("Perustiedot")}></PageHeader></div>
        <div className="col">
        <Input
              {...getFormikInputProps("account")}
              placeholder={t("Yritys")}
              label={t("account_input", "Yritys")}
              value={account.name}
              type="text"
              icon="fas fa-building"
              disabled={true}
            />
        </div>

        <div className="col">
        <Input
              {...getFormikInputProps("first_name")}
              placeholder={t("Etunimi")}
              label={t("first_name_input", "Etunimi")}
              type="text"
              icon="fas fa-user"
              required
            />
        </div>

        <div className="col">
        <Input
              {...getFormikInputProps("last_name")}
              placeholder={t("Sukunimi")}
              label={t("last_name_input", "Sukunimi")}
              type="text"
              icon="fas fa-user"
              required
            />
        </div>

        <div className="col">
        <Input
              {...getFormikInputProps("phone")}
              label={t("phone_input", "Puhelinnumero")}
              type="text"
              icon="fas fa-phone"
              
            />
        </div>

        <div className="col">
        <LanguageSelect
              {...getFormikInputProps("language")}
              label={t("language", "Kieli")}
              type="select"
              icon="fas fa-flag"
              required
              setFieldValue={setFieldValue}
            />
        </div>

        <div className="col">
        <Input
              {...getFormikInputProps("username")}
              label={t("email_input", "Sähköposti")}
              type="email"
              required
              disabled={true}
            />
            <div className="row margin-top-say">
              <div className="col">
              <Link> <Typography className="text-danger" onClick={() => openModalEdit(null, "change_password")}>{t("change_password", "Vaihda salasana")}</Typography></Link>
              </div>
              <div className="col te">
              <Link><Typography className="text-danger text-right" onClick={() => openModalEdit(null, "change_username")}>{t("change_username", "Vaihda käyttäjätunnus")}</Typography></Link>
              </div>

            </div>
            
        </div >

        </div>

        
        
        <div className="col profileImg">
          <div className="col">
          <PageHeader title={t("Profiilikuva")}></PageHeader>
          </div>

          <div className="col">
   
             { previewImage ? (
                
                <div className="col mb-2 mt-2">
                  <div  >
                  <span className="rounded-circle">
                  <img
                    alt="..."
                    src={previewImage}
                    className="rounded-circle"
                  />
                </span>
                  </div>
                </div>

              ) : (
                <div className="col mb-2 " >
                  { values?.new_profile_picture ? (
                    <div >
                      <span className=" rounded-circle">
                      <img
                        alt="..."
                        src={values.new_profile_picture}
                        className=" rounded-circle"
                      />
                    </span>
                    </div>
                    
                  ) : (
                    <>
                    {values?.profile_picture ? (
                      <div>
                      <span className=" rounded-circle">
                      <img
                      alt="..."
                      src={values.profile_picture}
                      className=" rounded-circle"
                      />
                      </span>
                      </div>
                    ) : (
                      <div>
                      <span className=" rounded-circle">
                      <img
                      alt="..."
                      src={defaultAvatar}
                      className=" rounded-circle"
                      />
                      </span>
                      </div>
                    )
                    }
                    </>
                  )}
                </div>

              )}
      
          </div>

          <div className="col">
          { Boolean(errors["new_profile_picture"]) && <Typography className="text-danger">{errors["new_profile_picture"]}</Typography> }
          </div>

          <div className="col">
          <ImageInput
          {...getFormikInputProps("profile_picture")}
          // label={t("profile_picture_input", "Profiilikuva")}
          id={`channeledit-cover-image`}
          selectedFile={values.new_profile_picture}
          handleChange={(e) => handleImageChange(e?.target?.files?.[0])}
          resetFile={handleResetImage}
          />
          </div>
          <div className="col bd-highlight">(jpg, png)</div>
          </div>
        </div>
        <div className="col mt-3">
            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {invitated_again === "invitated_again" ? t("invitate_again","Kutsu"):t("Save","Tallenna")}
            </Button>
        
           { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </div>
      

    </div>
    </>
  );
}

const UserEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        username: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
        first_name: Yup.string().required(required),
        last_name: Yup.string().required(required),
        phone: Yup.string().notRequired().test('phone', t('Puhelinnumero tulee olla muodossa esim. +358501234567'), (value) =>  {
          if (value) {
            const schema = Yup.string().phone("FI", true, t('Puhelinnumero tulee olla muodossa esim. +358501234567')).required(required)
            return schema.isValidSync(value);
          }
          return true;
        }),
        new_profile_picture: Yup.mixed().notRequired().test('fileSize', t('File too large, max size are 3 MB'), (value) => {
          if (value) {
            return value.size < 3000000;
          }
          return true;
        }).test('fileType', t('File type not allowed. Type must be ".png" or ".jpg".'), (value) => {
          if (value) {
            //allow only jpg and png

            console.log(value.type)
            return ['image/jpg', 'image/png'].includes(value.type);
          }
          return true;
        }),

      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, portfolio, account, newCover, getMyUser} = props;
      const {new_profile_picture,profile_picture, ...rest} = values;

      const formData = new FormData();

      if (new_profile_picture && new_profile_picture !== profile_picture) {
        formData.append('profile_picture', new_profile_picture, new_profile_picture.name);


      }

      Object.keys(rest).forEach(key =>{
        if (typeof rest[key] === 'object' && rest[key] !== null && !Array.isArray(rest[key])) {
          formData.append(key, rest[key]?.value)
        } 
        else if (Array.isArray(rest[key])) {
          if (rest[key].length > 0){
            rest[key].map(((listItem, index) => {
              if (typeof listItem === 'object' && listItem !== null) {
                formData.append(key+"["+index+"]", listItem?.value)
              } 
              else {
                formData.append(key+"["+index+"]", listItem)
              }
            }))
          }
          else {
            formData.append(key, rest[key])
          }
        }
        else {
          formData.append(key, rest[key])
        }
      });

      const data = formData;

      // let data = {
      //   ...values,
      //   first_name: values.first_name,
      //   last_name: values.last_name,
      //   phone: values.phone,
      // };

      // if (values.profile_picture){
      //   data.profile_picture = values.profile_picture;
      //   console.log("values.profile_picture", values.profile_picture)
      // }

      
      if (values?.id) {
        httpUpdateUser(values.id, data).then(res => {
          setSubmitting(false);
          //reload window
          if (new_profile_picture && new_profile_picture !== profile_picture){
            window.location.reload()
          }
          else {
             getMyUser()
            // props.notify({ title:t("User"), message:t("Updated")})
          }
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      

      
      
    },
    displayName: "BasicForm"
  
})(UserEdit)
    
    
const UserEditView = ({history, objectId, setCrumbLinks, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const { getPortfolioName, getUserTypeName } = useAppContext()
  const { getMyUser } = useAuthContext()

  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  const [modalRenderComponent, setModalRenderComponent] = useState()

  useEffect(() => {
  setCrumbLinks([
    {
      name: t("Etusivu"),
      url: "/dashboard",  
      },
    {name: t("Other"), url: "/dashboard/other/default_settings"},
    {name: t("user_account", "Oma tili"), url: "/dashboard/other/user_account"},
  ])
  }, [])
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id, component) => {

    setModalRenderComponent(component)
 
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    // getPortfolios()
    getObject()
    
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  const getObject = () => {
    
    setLoading(true)
    
    httpGetMyUser().then(res => {
        console.log(res)
      setPreSetValues({
        ...defaultValues,
        id: res?.data?.id,
        username: res?.data?.username,
        first_name: res?.data?.first_name,
        last_name: res?.data?.last_name,
        phone: res?.data?.phone === null ? "" : res?.data?.phone,
        profile_picture: res?.data?.profile_picture,
        language: res?.data?.language
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
      getObject()
  }, [])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <UserEditFormik 
      t={t}   
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId}
      account={account}
      openModalEdit={openModalEdit}
      getMyUser={getMyUser}
      {...rest} 
      />
     
      <Modal
      title={modalRenderComponent=== "change_password" ?t("change_password","Vaihda Salasana"): t("change_username","Vaihda Käyttäjätunnus")}
      isOpen={modalEditOpen}
      toggleModal={toggleModalEdit}
    >
      {modalRenderComponent === "change_username"
      ? <ChangeUsername
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        />
      : <ChangePassword
        objectId={modalEditId}
        handleResolve={modalEditResolve}
        handleReject={modalEditReject}
        invitated_again={modalRenderComponent}
        />
    }
    </Modal>

    </>
      )
      
}

export default UserEditView;
