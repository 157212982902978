import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  Form,
  FormGroup,
  Input as BootstrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Label,

} from "reactstrap";

import { Typography } from "stories/components"

import './Input.css';

export const Input = ({ type="text",styles, className, icon, inputText, iconColor,addonTypeClass, label, labelInline=false, onWheel,  focused=false, errorText, multiple=false,required=false, children, ...rest }) => {
  
  const [inputType, setInputType] = React.useState(type);

  let inputProps = {
    className: `custom_bootstrap_input ${className}`,
    type: inputType,
    onWheel: onWheel,
    ...rest,
  }
  // console.log(inputProps)
  

  const setShowPassword = () => {
    if (inputProps.type === "password") {
      setInputType("text")
    } else {
      setInputType("password")
    }
  }
  
  //rerender if inputProps.type changes
  React.useEffect(() => {
    setInputType(inputProps.type);
  }, [inputProps.type]);


  if (type === "decimal") {
    inputProps = {
      ...inputProps,
      type: "text",
      pattern: "[0-9]+([.,][0-9]+)?"
    }

  }
  
  return (
    <div className={`d-flex ${labelInline ? "flex-row align-items-center" : "flex-column"}`}>
      {label && (
        <Typography bold className={`pr-1 `} style={styles?.label?styles?.label:{}}>{label} {required ? "*" : ""}</Typography>
      )}
      <InputGroup className="input-group-merge input-group-alternative">
        { type === 'email' ? (
          <InputGroupAddon addonType="prepend">
            <InputGroupText className={`${iconColor} ${inputProps.disabled? "input-group-text-custom": null}`} >
              <i className={icon?icon:"ni ni-email-83"} />
            </InputGroupText>
          </InputGroupAddon>
        ) : null }
        { type === 'password' ? (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
              <i className={icon?icon:"ni ni-lock-circle-open"} />
              </InputGroupText> 
            </InputGroupAddon>
        ) : null }
        { type === 'text' ? ( 
          <>
            { icon && 
              <InputGroupAddon addonType="prepend">
                <InputGroupText className={`${iconColor} ${inputProps.disabled? "input-group-text-custom": null}`}>
                  <i className={icon?icon: null} />
                </InputGroupText> 
              </InputGroupAddon>
            }
          </>
        ) : null }
        { type === 'number' ? ( 
          <>
            { icon || (inputText && addonTypeClass =="prepend") &&
              <InputGroupAddon addonType={addonTypeClass}>
                <InputGroupText className={`${iconColor} ${inputProps.disabled? "input-group-text-custom form-control": null}`}>
                { icon && 
                  <i className={icon?icon: null} />
                }
                { inputText && inputText} 
                </InputGroupText> 
              </InputGroupAddon>
            }
          </>
        ) : null }

        
        { type === "select" ? (
          <>
            {icon && 
              <InputGroupAddon addonType="prepend">
                <InputGroupText className={`${iconColor} ${inputProps.disabled? "input-group-text-custom": null}`}>
                  <i className={icon} />
                </InputGroupText> 
              </InputGroupAddon>
            }
            <BootstrapInput
              {...inputProps}
              multiple={multiple}
            >
             {children}
            </BootstrapInput>
          </>
        ) : (
          <>
          <BootstrapInput
            {...inputProps}
          />
          { (inputText && addonTypeClass == "append") &&
              <InputGroupAddon addonType={ `${addonTypeClass}` } style={{height: "39px"}}>
                <InputGroupText className={`ml-0 ${iconColor} ${inputProps.disabled? "input-group-text-custom form-control": null}`}>
                { icon && 
                  <i className={icon?icon: null} />
                }
                { inputText && inputText} 
                </InputGroupText> 
              </InputGroupAddon>
            }
          </>
          
        )}



        { inputProps.name.includes("password") ? (
          <InputGroupAddon addonType="append">
            <InputGroupText onClick={() => setShowPassword()}>
              {inputType === "password" ? (
                <i className="fas fa-eye-slash text-secondary" />
              ) : <i className="fas fa-eye text-secondary" />
              } 
     
            </InputGroupText>
          </InputGroupAddon>
        ) : null }




        
      </InputGroup>
      { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
      )}
    </div>
  )
  
  
  
};

Input.propTypes = {
  errorText: PropTypes.string,
  focused: PropTypes.bool,
  label: PropTypes.string,
  labelInline: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text','email','password','number','date','datetime-local','select','textarea', 'file']),
};

