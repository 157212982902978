import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import Select from 'react-select';
import { tr } from "date-fns/locale";
import { TAGS } from "interweave";
import { Typography } from "stories/components"

import {
  FormGroup,
} from "reactstrap";

const PIF_UnlistedFundsSelect = ({ label,isMulti,showAll,placeholder,setFilters, onChange, value, name="type", ...rest }) => {
  const { t } = useTranslation();
  const { enums } = useAppContext()
  
  const getInputOptions = useCallback(() => {
    // const transactionTypes = enums?.transactions?.transaction?.type
    //filter and save only key 2,3,20,41
    // const transactionTypes = enums?.transactions?.transaction?.type.filter(obj => obj.key === 30 || obj.key == 50 )
    const transactionTypes = enums?.transactions?.transaction?.type.filter(obj => obj.key === 30 )
    return transactionTypes && transactionTypes.map(obj => {
        console.log("obj", obj)
      return (
        <option key={obj.key} value={obj.key}>{t(`transactionTypeValue_${obj.key}`, obj.value)}</option>
      )
    })
  }, [enums])


  const [files, setFiles] = useState([]);


  const handleSelectChange = (selectedOption) => {
      // setFilters(selectedOption)
      setFilters(s => ({
        ...s,
        calculate_type: selectedOption
      }))
      // if (setFileSelectValue) {
      //     console.log("selectedOption", selectedOption)
      // setFileSelectValue(selectedOption); // Set the selected value in the parent component

      // //append the selected value to the array
      // setFileSelectValue([...fileSelectValue, selectedOption]);
      // }
  };



  const getInputOptionsMulti = useCallback(() => {
      //if show all is true add all option
    // const transactionTypes = enums?.transactions?.transaction?.type.filter(obj =>  obj.key === 30  || obj.key == 50 )
    const transactionTypes = enums?.transactions?.transaction?.type.filter(obj =>  obj.key === 30 )
    //add frist empty option

      let inputoptions = []
      if (showAll) {
          inputoptions = [{key: " ", value: t("Valitse")}]
      }
      if (transactionTypes){

        inputoptions = [...inputoptions, ...transactionTypes]
      }

      return transactionTypes && inputoptions && inputoptions.map(obj => {
          return (
          {value:obj.key, label: t(`transactionTypeValue_${obj.key}`, obj.value)}
          )
      })
  }, [enums])
  
  return (
    <>

    {isMulti ?
      (
        <>
          <Typography bold>{label}</Typography>
        <Select 
        label={label || label === "" ? label : t("transaction_type_select_label", "T")}
        name={name} 
        options={getInputOptionsMulti()} 
        onChange={handleSelectChange}
        placeholder={placeholder?placeholder:t("transaction_type_select_label", "T")}
        value={value}
        isMulti={isMulti}
        
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%'  // Make the select component take the full width of the container
          })
        }}
        // styles={{
          //     menuPortal: base => ({
            //       ...base,
            //       zIndex: 9999 // Set the desired z-index for the dropdown menu
            //     })
            //   }}
            {...rest} 
            />
        </>
      
      ):(
        
        <Input label={label ? label : t("transaction_type_select_label", "T")} type="select" name={name} onChange={onChange} value={value} {...rest}>
        <option key="null" value="" label={t("Valitse")}></option>
        {getInputOptions()}
        </Input>
      )
      
    }
    </>
  )
  
  
}

export default PIF_UnlistedFundsSelect;