import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAuthContext } from "contexts/AuthContext"
import { useAppContext } from "contexts/AppContext"

import { httpGetUser, httpCreateUser, httpUpdateUser, httpInviteUser } from "services/users" 

import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner, BreadcrumbsDynamic } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"

import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"



const PortfoliosList = ({match, history, location}) => {
  
  const { t } = useTranslation();
  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const setPortfolio = useStoreActions((actions) => actions.portfolio.setPortfolio);

  const loading = useStoreState((state) => state.portfolio?.loading);
  const count = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const account = useStoreState((state) => state.account?.account);

  const { getCompanyInstance } = useAppContext()

  const { isAdmin } = useAuthContext()
  const { myUser } = useAuthContext()
  

  const getCrumbLinks = () => {
    const crumbLinks = [
      {name: t("Etusivu"), url: "/dashboard/"},
      {name: t("portfolios_list_title","Salkut"), url: "/dashboard/portfolios"},
    ]

    //if location state include crumbLinks return them
    if (location?.state && location?.state?.crumbLinks) {
      location.state.crumbLinks.push({name: t("portfolios_list_title","Salkut"), url: "/dashboard/portfolios"})
      return location.state.crumbLinks
    }
    //else return default links
    return crumbLinks
  }
  
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })
  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const headers = [
    { label: t("portfolios_list_name", "Salkku"), key: "name"},
    { label: t("portfolios_list_currency_label", "Valuutta"), key: "currency_label"},
    { label: t("portfolios_list_sell_profit_calculation_method", "Laskentamenetelmä"), key: "sell_profit_calculation_method"},
    { label: t("portfolios_list_company", "Yritys"), key: "company"},

    // { label: t("portfolios_list_value", "Markkina-arvo"), key: "value"},
    // { label: t("portfolios_list_expenses", "Hankintameno"), key: "expenses"},
    // { label: t("portfolios_list_profit", "Tuotto"), key: "profit"},
    // { label: t("portfolios_list_change", "Muutos"), key: "change"},
    // { label: t("portfolios_list_change_eur", "Muutos EUR"), key: "change_eur"},
    // { label: t("portfolios_list_other_profit_expenses", "Muut tuotot ja kulut"), key: "other_profit_expenses"},
    { label: t("portfolios_list_actions", " "), key: "actions"},
  ];

  const passPortfolio  = (id) => {
    // get query params id
      console.log("id", id)
      const filtered = portfolios.filter(o => o.id == id)
      if (filtered && filtered.length > 0) {
        setPortfolio(filtered[0])
      }

      let data = {
        active_portfolio: Number(id),
      };
      
      if (myUser?.id) {
        httpUpdateUser(myUser.id, data).then(res => {
          // props.notify({ title:t("Asiakkuus vaihdettu"), message:t("")})
          //reload page
          // window.location.reload();
          // props.history.push("/dashboard/users/list");
        }, error => {
          if (error?.data?.detail) {
            console.log(
              error?.data?.detail,
            )
          }
  
          else {
            console.log(
              t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            )
          }
        })
      }
  }




  
  
  
  const getTableRows = useCallback(() => {
    return portfolios && portfolios.map((row, index) => {
      return {
        ...row,
        name: (
          <Link  to={{pathname:"/dashboard/portfolios/summary",search: `?id=${row.id}&name=${row.name} `, state: {stockId:row.id, stockName: row?.name}}}>
            <h4 className=" text-dark m-0" onClick={() => passPortfolio(row?.id)}>
            {row?.name || "-"}
            </h4>
          </Link>
        ),
        company: getCompanyInstance(row?.company)?.name || "-",
      
        actions: (
          <div className="d-flex justify-content-end">
            <Link to={{pathname:"/dashboard/portfolios/summary",search: `?id=${row.id}&name=${row.name} `, state: {stockId:row.id, stockName: row?.name}}}>
              <Button onClick={() => passPortfolio(row?.id)} className="btn-sm" color="secondary">{t("portfoliost_list_summary","Yhteenveto")}</Button>
            </Link>
            <Button className="btn-sm ml-3" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(row?.id)}>{t("Muokkaa")}</Button>
          </div>
        ),
      }
    })
  }, [portfolios])
  
  // Modal: New/Edit portfolio
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getPortfolios()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

    // Modal: open/edit Subscription
  
    const [modalSubscriptionEditOpen, setModalSubscriptionEditOpen] = useState(false)
    const [modalSubscriptionEditId, setModalSubscriptionEditId] = useState()
    
    const toggleModalSubscriptionEdit = () => {
      setModalSubscriptionEditOpen(s => !s);
    }


    
    const openModalSubscriptionEdit = (id) => {
      setModalSubscriptionEditOpen(true)
      setModalSubscriptionEditId(id);
    }
    
    const modalSubscriptionEditResolve = () => {
      setModalSubscriptionEditOpen(false);
      setModalSubscriptionEditId(null);
    }
    
    const modalSubscriptionEditReject = () => {
      setModalSubscriptionEditOpen(false);
      setModalSubscriptionEditId(null);
    }


          //---------------------------- Update subscription BEGIN---------------------------------

  const openUpdateUp = useCallback(() => {
    return (<>{ <UpdateUp redirectToFrontpage={false} forceOpen={true}/>}</>)
  }, [])

  // Update subscription END
  
  return (
    <>

        <PageHeader title={t("portfolios_list_title","Salkut")} />
        <BreadcrumbsDynamic links={getCrumbLinks()} />
        {modalSubscriptionEditOpen && <UpdateUp redirectToFrontpage={false} forceOpen={true} closeParent={() => setModalSubscriptionEditOpen(false)}/>}
      <PageContainer>
      
        <div className="d-flex justify-content-between mb-2">
          <div className="p-2">
          {portfolios.length >= account?.license?.limit_portfolios && isAdmin?(
            <Button color="secondary" disabled={isAdmin === false} onClick={() => openModalSubscriptionEdit(null)}>{t("portfolios_list_create_portfolio","Uusi salkku")}</Button>
          ):(
            <Button color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(null)}>{t("portfolios_list_create_portfolio","Uusi salkku")}</Button>
          )
           }
          </div>
          
          <div className="p-2">
            
          </div>
        </div>

        
        <TableAuto
          showRowNumber={false}
          showId={true}
          checkboxes={false}
          headers={headers}
          headerColor={"light"}
          rows={getTableRows()}
          loading={loading}
          pagination={true}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={count}
          setFilters={setFilters}
          name="portfolios_table"
        />
        
        <Modal
          title={t("portfolios_list_portfolio_title","Salkku")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
          <PortfolioEdit
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>

        {/* <Modal
          isOpen={modalSubscriptionEditOpen}
          toggleModal={toggleModalSubscriptionEdit}
          size="modal-xl"
          showHeader={false}
          classNameBody="p-0 padding-0"
        >
          <SubscriptionOptionsInfo
            objectId={modalSubscriptionEditId}
            handleResolve={modalSubscriptionEditResolve}
            handleReject={modalSubscriptionEditReject}
            custom_info_text= {t("subscription_portfolios_info_text", "Salkkujen ensimmäismäärä tilauksellanne on täyttynyt")}
          />
        </Modal> */}
      </PageContainer>
    </>
  );
}

export default PortfoliosList;
