import React, { useEffect, useState, useCallback } from "react";
import moment, { updateLocale } from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { Card, Typography, Button, Link, IconButton, Modal, Spinner } from "stories/components";
import { PageHeader, PageContainer } from "components/Page"
import { TableAuto } from "stories/tables"

import PortfolioEdit from "views/app/PortfolioEdit/PortfolioEdit"
import UserInvitate from "views/dashboard/UserInvitate/UserInvitate"
import CompanyEdit from "views/dashboard/CompanyEdit/CompanyEdit"

import { httpGetAccountUsers, } from "services/account_users"
import { httpInviteUser} from "services/users"
// import from appcontext
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { useNotification } from "stories/components/Notification"
import { httpGetCompanies } from "services/companies";

import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"




//use appcontext to get user data



const CompaniesList = ({match, history, setCrumbLinks}) => {
  
  const { t } = useTranslation();
  const companies = useStoreState((state) => state.companies?.companies)
  const getCompanies = useStoreActions((actions) => actions.companies?.getCompanies)

  // const loading = useStoreState((state) => state.portfolio?.loading);
  const portfoliosCount = useStoreState((state) => state.portfolio?.count);
  const getPortfolios = useStoreActions((actions) => actions.portfolio.getPortfolios);
  const { getPortfolioName, getUserTypeName } = useAppContext()
  const [loading, setLoading] = useState(false)
  const { notify } = useNotification();
  const { isAdmin } = useAuthContext()
  
  const account = useStoreState((state) => state.account?.account);


  useEffect(() => {
    setCrumbLinks([
      {
        name: t("Etusivu"),
        url: "/dashboard",  
        },
      {name: t("Other"), url: "/dashboard/other/default_settings"},
      {name: t("account_companies", "Yritykset"), url: "/dashboard/other/account_companies"},
    ])
    }, [])

  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })

  
  // Table
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })


  
  const getHeaders = (param) => {
  const headers = [
      { label: t("companies_list_name", "Nimi"), key: "name"},
      { label: t("companies_list_identity_code", "Y-tunnus"), key: "identity_code"},
      { label: t("companies_list_portfolios", "Salkut"), key: "portfolios"},

    { label: t("companies_list_actions", " "), key: "actions"},
  ];
  return headers
  }

  
  const handleChange = ({target}) => {
    setFilters({
      ...filters,
      [target.name]: target.value,
    })
  }
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  

  
  const getTableRows = useCallback(() => {
    return companies && companies.map((row, index) => {
      return {
        ...row,
        name: (
            <Link>
            <h4 className=" text-dark m-0" onClick={() => openModalEdit(row?.id)}>
            {row?.name || "-"}
            </h4>
            </Link>
        ),
        identity_code: row?.business_identity_code,
        portfolios: "",
        actions: (
          <div className="d-flex justify-content-end">
            <Button className="btn-sm" color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(row?.id)}>{t("edit_button","Muokkaa")}</Button>
          </div>
        ),
      }
    })
  }, [companies])



  

  
  // Modal: New/Edit company
  
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    getCompanies()
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  

  // Modal: open/edit Subscription

  const [UpdateUpOpen, setUpdateUpOpen] = useState(false)
  const [modalSubscriptionEditId, setModalSubscriptionEditId] = useState()
  
  
  const callToOpenUpdateUp = (id) => {
    setUpdateUpOpen(true)
  }
  
 


  // // Update subscription BEGIN

  const openUpdateUp = useCallback(() => {
    return (<>{ UpdateUpOpen && <UpdateUp redirectToFrontpage={false} closeParent={() => setUpdateUpOpen(false)}/>}</>)
  }, [UpdateUpOpen])

  // END


  return (
    <>

        {openUpdateUp()}
        <div className="d-flex justify-content-between mb-2">
          <div className="p-2">

            { account?.license?.type === "private" ? (
              <Button color="secondary" disabled={isAdmin === false} onClick={() => callToOpenUpdateUp(null)}>{t("create_company","Uusi yritys")}</Button>
              ):(
              <Button color="secondary" disabled={isAdmin === false} onClick={() => openModalEdit(null, "invitate")}>{t("create_company","Uusi yritys")}</Button>
            )
            }

          </div>
          
          <div className="p-2">
            
          </div>
        </div>

        
        <TableAuto
          showRowNumber={false}
          showId={true}
          checkboxes={false}
          headers={getHeaders()}
          headerColor={"light"}
          rows={getTableRows()}
          loading={loading}
          pagination={true}
          paginationPrevious={paginationPrevious}
          paginationNext={paginationNext}
          paginationOffset={filters?.offset}
          paginationLimit={filters?.limit}
          paginationCount={null}
          setFilters={setFilters}
        />
        



      
        <Modal
          title={t("companies_list_company_title","Yritys")}
          isOpen={modalEditOpen}
          toggleModal={toggleModalEdit}
        >
          <CompanyEdit
            objectId={modalEditId}
            handleResolve={modalEditResolve}
            handleReject={modalEditReject}
          />
        </Modal>

    </>
  );
}

export default CompaniesList;
