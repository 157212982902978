import { React, useCallback, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';


import MenuList from "./MenuList";
import Option from "./Option";

import {
  FormGroup,
} from "reactstrap";

import { Typography } from "stories/components"
import "./SelectMulti.css"

const CustomClearText = () =>  <i className={"fas fa-times-circle"} />;
const ClearIndicator = props => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '10px 5px' }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'black' : 'black',
});

export default function SelectMulti({showDefaultAsync=false, loading, async=false,showAllOption=false,name,className="select_multi_select",style, setValueToSelect=false, ratesView=false, isMulti=true, disabled=false,closeMenuOnSelect=false,placeholder, errorText, defaultValues, focused, label, options, required, value, setFieldValue, ...rest}) {
  const { t } = useTranslation();
  const allOption = {value: '', label: t("All")}
  const {setIsSelectOpen, isSelectOpen} = rest

  if (isMulti === false) {
    closeMenuOnSelect = true
  }

  const handleSelectChange = (obj, child) => {
    console.log(child)
    if (child?.action === "select-option") {
      if (isMulti) {
        setFieldValue(child.name, [
          ...value,
          {id:child.option?.value, name:child.option?.label}
        ])
      }
      else {
        // setFieldValue(child.name, {...obj, id:obj?.value, name:obj?.label})
        if (child?.name){
          console.log("child.name", child.name)
          setFieldValue(child.name, {...obj, id:obj?.value, name:obj?.label})
        }
        
      }
    }
    else if (child?.action === "remove-value") {
      if (isMulti) {
        let newValues = [
          ...value
        ]
        const index = newValues.findIndex(obj => obj.id == child?.removedValue?.value)
        newValues.splice(index, 1)
        setFieldValue(child.name, newValues)
      }
      else {
        setFieldValue(child.name, "")
      }
    }
    else if (child?.action === "clear") {
      if (isMulti) {
        setFieldValue(child.name, [])
      }
      else {
        setFieldValue(child.name, "")
      }
    }
  }

  // const handleSelectClick = (obj, child) => {
  //   setIsSelectOpen(!isSelectOpen);
  // }

  
  const getOptions = () => {
    if (showAllOption === true) {
    // add color to all option by looping through all options
      options = options.map(obj => {
        return {...obj, color: "#000000"}
      })


    return [allOption, ...options]
    }
    else {
      return options
    }
  }



  const filterOptions = (inputValue) => {
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  
  const loadOptions = (inputValue, callback) => {
  // Check if inputValue has at least 3 characters
  if (inputValue.length >= 3) {
    setTimeout(() => {
      callback(filterOptions(inputValue));
    }, 1000);
  } else {
    // If less than 3 characters, callback with an empty array
    callback([]);
  }
  };

  const getValue = useCallback(() => {
    if (isMulti) {
      //loop through value and if prop id change that to value
      let newValues = []
      if (value?.length > 0) {
        value.forEach(obj => {
          if (obj?.id) {
            newValues.push({value: obj?.id, label: obj?.name ? obj?.name : obj?.value})
          }
          else {
            newValues.push(obj)
          }
        })
      }

      return newValues
    }
    else {
      return value
    }
  }, [value, isMulti])

  



  const getSelect = useCallback(() => {
    if (async === true){

      return(
        <FormGroup
        className={`${focused} ? "focused" : ""} my-0` } style={{style}} 
       >
                {label && (
            <Typography bold className={`labelInline ? "d-inline" : ""`}>{label} {required ? "*" : ""}</Typography>
          )}
        <AsyncSelect 

          {...rest}
          loadOptions={loadOptions} 
          className={className}
          defaultOptions={showDefaultAsync && options}
          // defaultValue={defaultValues}

          style={style}
          value={value}
          placeholder={placeholder ? placeholder: t("Hae...")}
          onChange={handleSelectChange}
          name={name}
          closeMenuOnSelect={closeMenuOnSelect}
          noOptionsMessage={() => () => { return t("No choices")}}
          isLoading={loading}
          options={getOptions()}  
          isDisabled={disabled}
      
        />
      </FormGroup>
      
      )
      }
    return (
      <>

        <FormGroup
            className={`${focused} ? "focused" : ""} my-0` } style={{style}} 
           >
            {label && (
              <Typography bold className={`labelInline ? "d-inline" : ""`}>{label} {required ? "*" : ""}</Typography>
            )}
        
            {
              ratesView || setValueToSelect ? (
                <Select
                {...rest}
                closeMenuOnSelect={closeMenuOnSelect}
                className={className}
                // components={{ ClearIndicator, Option }}
                value={value}
                defaultValue={defaultValues}
                isDisabled={disabled}
                isMulti={isMulti}
                options={getOptions()}
                placeholder={placeholder ? placeholder: t("Valitse...")}
                noOptionsMessage={() => () => { return t("No choices")}}
                onChange={handleSelectChange}
                name={name}
                style={style}
    
                // menuIsOpen={true}
              />
              ):(
                <Select
                {...rest}
                closeMenuOnSelect={closeMenuOnSelect}
                className={className}
                // components={{ ClearIndicator, Option }}
                // value={isMulti ? undefined : value}
                value={getValue()}
                defaultValue={defaultValues}
                isDisabled={disabled}
                isMulti={isMulti}
                options={getOptions()}
                placeholder={placeholder ? placeholder: t("Valitse...")}
                noOptionsMessage={() => () => { return t("No choices")}}
                onChange={handleSelectChange}
                name={name}
                style={style}
                // menuIsOpen={true}
    
              />
              )
            }
      
            
            { errorText && (
            <Typography className="text-danger">{errorText}</Typography>
            )}
        </FormGroup>
      
      </>
      );
  }, [value, isMulti, disabled, closeMenuOnSelect, placeholder, errorText, defaultValues, focused, label, options, required, name, style, className, rest])
    
  
  return (
  <>
  
  {getSelect()}
  
  </>
  );
}

SelectMulti.defaultProps = {
  menuPlacement: "auto",
}

/*
export const exampleOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];
*/