import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography,Link, Spinner, Modal } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";

import ChangePassword from "views/dashboard/ChangePassword/ChangePassword"
import ChangeUsername from "views/dashboard/ChangeUsername/ChangeUsername"


import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { PageWrapper, PageHeader } from "components/Page"


import { httpGetPortfolio, httpCreatePortfolio, httpUpdatePortfolio, httpDeletePortfolio } from "services/portfolios"
import { httpGetAccountUser, httpUpdateAccountUser, httpDestroyAccountUser } from "services/account_users"
import { httpGetMyUser, httpUpdateUser } from "services/users"
import { httpUpdateAccount } from "services/accounts"


import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"

import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"

import { round, roundScaleDecimals } from "services/helpers"  

import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"
import LicensePayment from "views/vismapay/LicensePayment/LicensePayment"

require('yup-phone');

const defaultValues = {
  id: null,
  license: ""
}

const AccountSettings = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues,
     invitated_again,
     openModalEdit,
     openModalSubscription
   } = props;
   
   const {
      t,
      history,
      objectId,
      account,
      myUser,
      selected_license
   } = props;

  const { getLicenseName } = useAppContext()
  const { isAdmin, accountUserType } = useAuthContext()



   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    httpDestroyAccountUser(objectId).then(() => {
        props.handleResolve()
    })
  }
  
  return (
    <>
    
    <Row className="mt-1">
    <Col xl-4 lg-8 >
    <Typography bold variant="h2" className="text-dark mt-3 mb-4">{t("subscription_title","Tilaus ja Sopimus")}</Typography>
        <Row>
          <Col className="mb-3">
              <Input
              {...getFormikInputProps("name")}
              placeholder={t("Yritys")}
              label={account?.type === "private" ? t("account_owner","Tilin omistaja") : t("account_owner","Tilin omistaja")}
              type="text"
              icon={account?.type === "private" ?"fas fa-user" :"fas fa-building"}
              disabled={accountUserType!=="admin" }
              />
          </Col>
        </Row>
{/* 
        <Row>
          <Col className="mb-3">
            <Input
              {...getFormikInputProps("account_type")}
              // icon="fas fa-user-circle"
              placeholder={t("Yrityskäyttäjä")}
              setFieldValue={setFieldValue}
              disabled={true}
              label={t("Tilin tyyppi")}
            />
          </Col>
        </Row>    */}
        <Row className="mt-4">
          <Col className="mb-3">
          <Input
              {...getFormikInputProps("license")}
              // icon="fas fa-certificate"
              value={selected_license?.name}
              setFieldValue={setFieldValue}
              disabled={true}
              label={t("Lisenssi")}
          />
          {/*
          <Typography variant="small" className="text-danger">{t("Voit vaihtaa tilauksen kerran vuodessa ottamalla yhteyttä tukeemme")}</Typography>
          */}
          
          {selected_license?.price / (1 + (selected_license?.price_vat_percent / 100.0))== null?(
            <Typography>{t("subscription_month_price","Kuukausihinta")} {selected_license?.price} € ({t("sk_vat_short","alv")} {"0.0"}%)</Typography>
          ):(
            <>
            {
              account?.license_price == null || Number(account?.license_price) == 0 ?(
                <Typography>{t("subscription_month_price","Kuukausihinta")} 0 € ({t("sk_vat_short","alv")} {"0.0"}%)</Typography>
                ):(
                  <>
                    {
                      Number(account?.license_price_vat_percent) == 0 ?(

                        <Typography>{t("subscription_month_price","Kuukausihinta")} { roundScaleDecimals(Number(account?.license_price),2)} € ({t("sk_vat_short","alv")} {"0.0"}%)</Typography>
                      ):(
                        <>
                          {account?.license?.name == "Basic" ?(
                            <Typography>{t("subscription_month_price","Kuukausihinta")} { roundScaleDecimals(Number(account?.license_price),2)} € ({t("sk_vat_short","alv")} {account?.license_price_vat_percent}%)</Typography>
                          ):(
                            <Typography>{t("subscription_month_price","Kuukausihinta")} { roundScaleDecimals(Number(account?.license_price) / (1 + (Number(account?.license_price_vat_percent) / 100.0)),2)} € ({t("sk_vat_short","alv")} {"0.0"}%)</Typography>
                          )}
                        </>
                      ) 
                    }
                  </>

                  )
            }
            </>
          )
          }
          </Col>
        </Row>
        
        {accountUserType === "admin" && (
          <Row>
              <Col>
                  <Button onClick={openModalSubscription}>{t("web_subscription_upgrade_lisense","Vaihda tilauspaketti")}</Button>
              </Col>
          </Row>
        )}



        <Typography bold variant="h4" className="text-dark mt-3 mb-1">{t('subscription_second_header', 'Tilausehdot')}</Typography>
        <Row>
          <Col className="mb-1">
            <div className="d-inline-flex">
            {/*
              <Button variant="secondary" disabled={isSubmitting} onClick={() => openModalEdit()}>
                {t("contract_agreement","Näytä sopimus PDF")}
              </Button>
            */}
            <Link href="https://www.investime.fi/legal/" target="_blank">
            <Button variant="secondary" disabled={isSubmitting} >
            {t("subscrition_terms","Tilaus- ja toimitusehdot")}
            </Button>
            </Link>
            </div>

      
          </Col>
        </Row>
        <Typography variant="small" className="text-dark  mb-5">{t('subscription_order_date', 'Palvelu tilattu')} { moment(myUser?.user_activated_date).format('DD.MM.YYYY')}</Typography> 




      </Col>
      <Col >

          
      </Col>
      <Col >
      <Typography bold variant="h2" className="text-dark mt-3 mb-4">{t("subscription_custom_order","Tilaa räätälöinti?")}</Typography>
      
      <Typography variant="medium" className="text-dark mt-3 mb-1">{t("subscription_contact_support","Ota yhteyttä tukipalveluumme.")}</Typography>
      <Typography bold variant="h4" className="text-dark mt-3 mb-1">{t("support@investime.fi")}</Typography>
          
      </Col>
      </Row>

      <Row>
                <Col className="mb-5">
                  <Button variant="secondary" disabled={isAdmin === false} onClick={onSubmit}>
                    {t("Save","Tallenna")}
                  </Button>
              
                { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </Col>
      
                <Col className="mb-3 text-right">
      
      
          </Col>
        </Row>
    </>
  );
}

const AccountSettingsFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("The field is required");
      return Yup.object().shape({
        name: Yup.string().required(required),
      
        

      });
    },
    handleSubmit: (values,{ setSubmitting, setErrors, props }) => {
      const { t, setLanguage, account, getAccount } = props;

      let data = {
        account: account.id,
        name: values?.name,
        license: values?.license,
        type: values?.account_type,
      };
      
      if (true) {
        httpUpdateAccount(account.id, data).then(res => {
          setSubmitting(false);
          
          //reload page
          props.notify({ title:t( "Tili"), message:t("Updated", "Päivitetty")})
          getAccount();
        }, error => {
          console.log(error.status)
          setSubmitting(false);
          
          //All keys in error.data
          const errorDataKey = Object.keys(error?.data);
          //default
          let errorCodeKey = errorDataKey?.[0];
          //default errorCode string to translator
          let errorCodeData = error?.data?.[errorCodeKey];
          //if error.data include errorCode then use that instead of default (errorCodeKey, errorCodeData). Also exclude errorCode key from errorDataKey list
          if (errorDataKey.length >= 2 && errorDataKey.includes('errorCode') ) {
            const errorCodeIndex = errorDataKey.indexOf('errorCode');
            errorCodeKey = errorDataKey[errorCodeIndex];
            errorCodeData = error?.data?.[errorCodeKey];
            //Exclude errorCode key from errorDataKey list
            errorDataKey.splice(errorDataKey.indexOf('errorCode'), 1);
            //For special cases, if render depends on errorCode
            setErrors({[errorCodeData]: t(errorCodeData)});
        
          }
          console.log(errorDataKey)
          console.log(errorCodeKey)
          console.log(errorCodeData)
          let errorData = error?.data[errorDataKey?.[0]];
          const valuesKeys = Object.keys(values);
          const usualErrorkeys = ['detail', 'message', 'non_field_errors'];
  
  
          if (Array.isArray(errorData)) {
            errorData = errorData[0];
          }
  
          if (!errorData || errorData === '') {
            setErrors({general: t('general_error','Virhe. Ota tarvittaessa yhteyttä asiakaspalveluun'),[errorCodeData]: t(errorCodeData)})
          }
          else if (errorDataKey.length > 0 && valuesKeys.includes(errorDataKey?.[0])) {
            //set new setErrors with include previous errors
          
            setErrors({[errorDataKey?.[0]]: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
            
  
          }
          else if (errorDataKey.length > 0 && usualErrorkeys.includes(errorDataKey?.[0])) {
            setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
  
          } 
          // else if dataKey is exist set general error
          else if (errorDataKey.length > 0) {
            setErrors({general: t(errorCodeData,errorData),[errorCodeData]: t(errorCodeData)});
          }
          else {
              setErrors({
                general: `${t('general_error','Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,[errorCodeData]: t(errorCodeData),
            })
          }
        })
      }

      
      
    },
    displayName: "BasicForm"
  
})(AccountSettings)
    
    
const AccountSettingsView = ({history,setCrumbLinks, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { getLicenseName } = useAppContext()
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const account = useStoreState((state) => state.account?.account);
  const licenses = useStoreState((state) => state.licenses?.licenses);
  const getAccount = useStoreActions((actions) => actions.account.getAccount);

  const { getPortfolioName, getUserTypeName, getAccountTypeObject, enums } = useAppContext()
  const { getMyUser, setLanguage, myUser } = useAuthContext()

  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditId, setModalEditId] = useState()
  const [selected_license, setSelected_license] = useState()

  useEffect(() => {
    if (licenses){
      setSelected_license(licenses?.find((item) => item.id === account?.license?.id))  
    }
  }, [licenses])

  useEffect(() => {
    setCrumbLinks([
      {
        name: t("Etusivu"),
        url: "/dashboard",  
        },
      {name: t("Other"), url: "/dashboard/other/default_settings"},
      {name: t("default_settings", "Perusasetukset"), url: "/dashboard/other/default_settings"},
    ])
    }, [])
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {

 
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }
  
  useEffect(() => {
      setPreSetValues({
        ...defaultValues,
        license: account.license?.id,
        name: account.name,
        account_type: getAccountTypeObject(account.type)?.value,
      });
  }, [account, enums])
  
  // Subscription BEGIN
  const [modalSubscriptionOpen, setModalSubscriptionOpen] = useState(false)
  const [chosenLicenseId, setChosenLicenseId] = useState()
  
  const setSubscriptionFieldValue = (key, value) => {
    console.log("setSubscriptionFieldValue");
    console.log("key", key);
    console.log("value", value);
    if (value) {
        setChosenLicenseId(value);
    }
  }
  
  const toggleModalSubscription = () => {
    setModalSubscriptionOpen(s => !s);
  }
  
  const openModalSubscription = () => {
    setModalSubscriptionOpen(true)
  }
  
  const modalSubscriptionResolve = () => {
    setModalSubscriptionOpen(false);
  }
  
  const modalSubscriptionReject = () => {
    setModalSubscriptionOpen(false);
  }
  
  // END
  
  // Payment BEGIN
  const [licensePaymentModalOpen, setLicensePaymentModalOpen] = useState(false)
  
  const toggleLicensePaymentModal = () => {
    setLicensePaymentModalOpen(s => !s);
  }
  
  const openLicensePaymentModal = () => {
    setLicensePaymentModalOpen(true)
  }
  
  const resolveLicensePaymentModal = () => {
    setChosenLicenseId(null);
    setLicensePaymentModalOpen(false);
  }
  
  const rejectLicensePaymentModal = () => {
    setChosenLicenseId(null);
    setLicensePaymentModalOpen(false);
  }
  
  useEffect(() => {
    if (chosenLicenseId && chosenLicenseId > 0) {
        openLicensePaymentModal()
    }
      
  }, [chosenLicenseId])
  
  // END
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
        <AccountSettingsFormik 
            t={t}   
            history={history} 
            preSetValues={preSetValues} 
            notify={notify} 
            objectId={objectId}
            account={account}
            getAccount={getAccount}
            openModalEdit={openModalEdit}
            setLanguage={setLanguage}
            myUser={myUser}
            selected_license={selected_license}
            openModalSubscription={openModalSubscription}
            {...rest} 
        />
        
        <Modal
            title={t("Sopimus")}
            isOpen={modalEditOpen}
            toggleModal={toggleModalEdit}
        >
            <div>
                Toiminto on vasta tulossa!
            </div>
        </Modal>
        
        <Modal
          isOpen={modalSubscriptionOpen}
          toggleModal={toggleModalSubscription}
          size="modal-xl"
          showHeader={false}
          classNameBody="p-0 padding-0"
        >
          <SubscriptionOptionsInfo
            handleResolve={modalSubscriptionResolve}
            handleReject={modalSubscriptionReject}
            setFieldValue={setSubscriptionFieldValue}
            currentLicenseId={account?.license?.id}
          />
        </Modal>
        
        <Modal
          isOpen={licensePaymentModalOpen}
          toggleModal={toggleLicensePaymentModal}
          size="modal-lg"
          showHeader={false}
        >
          <LicensePayment
            licenseId={chosenLicenseId}
            handleReject={rejectLicensePaymentModal}
            hasActiveLicense={true}
          />
          
        </Modal>

    </>
      )
      
}

export default AccountSettingsView;
