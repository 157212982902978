import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input } from "stories/forms";
import { useAppContext }from "contexts/AppContext"
import { useStoreState, useStoreActions } from 'easy-peasy';



const InvestmentTypeSelectSub = ({ label, name="investment_type_sub",defaultValue, onChange,errorText, showAll=true, value, icon, disabled,}) => {
  const { t } = useTranslation();
  const { enums, getSecurityType } = useAppContext()
  const myAccount = useStoreState((state) => state.account?.account);
  const always_supported_keys = ['private_stock',"all", 'private_investment_fund','private_investment_fund_unlisted', 'derivative', 'loans_listed', 'loans', 'other','index','listed_stock']
  
  const getInputOptions = useCallback(() => {
    
    // const private_investment_funds_types = enums?.private_investment_funds?.private_investment_fund?.type
    let investmentTypesOrg = enums?.transactions?.transaction?.related_model_type

    investmentTypesOrg = investmentTypesOrg?.filter(obj => obj?.key === 20)

    let investmentTypes = enums?.private_investment_funds?.private_investment_fund?.type
    // add private investment funds types to investment types
    // investmentTypes =  [...investmentTypesOrg, ...investmentTypes]
    investmentTypes =  [...investmentTypes]

    // Remove elements that don't meet the condition
    investmentTypes = investmentTypes.filter(obj => {
      return always_supported_keys.includes(obj.key) || myAccount?.license?.features.some(feature => feature.name === obj?.key);
    });

    return investmentTypes && investmentTypes.map(obj => {
      return (
        <option value={obj.key}>{t(obj.key, obj.value)}</option>
      )
    })
  }, [enums])
  
  return (
    <Input  
      label={label || label === "" ? label : t("sub_security_type", "Rahoitusinstrumentti tyyppi")} 
      type="select" 
      name={name} 
      onChange={onChange} 
      value={value} 
      disabled={disabled}
      icon={icon}
      defaultValue={defaultValue}
      errorText={errorText}

    >
      {/*<option value=""></option>*/}
      {showAll &&
        <option value="" label={t("-")}></option>
      }
      {getInputOptions()}
    </Input>
  )
  
  
}

export default InvestmentTypeSelectSub;